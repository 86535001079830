import React from "react"

import Layout from "../../../layouts/default"

import Breadcrumb from "../../../components/breadcrumb"
import Bullet from "../../../components/bullet"
import BulletList from "../../../components/bullet-list"
import Paragraph from "../../../components/paragraph"
import Divider from "../../../components/divider"
import MarkerHighlight from "../../../components/marker-highlight"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import TextLink from "../../../components/textlink"

const LeichteSpracheRegeln = () => {
  return (
    <Layout>
      <Seo
        title="Regeln"
        description="Diese Regeln gibt es im Hölderlin∙turm"
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Leichte Sprache",
              link: "/leichte-sprache",
            },
            {
              title: "Besuch",
              link: "/leichte-sprache/besuch",
            },
            {
              title: "Regeln",
              link: "/leichte-sprache/besuch/regeln",
            },
          ]}
        />
        <PageTitle>Diese Regeln gibt es im Hölderlin∙turm</PageTitle>
        <Paragraph>
          Im Museum dürfen Sie nicht essen und trinken. <br />
          In der Nähe vom Hölderlin∙turm gibt es aber{" "}
          <TextLink to="/leichte-sprache/besuch/essen-und-trinken">
            Möglichkeiten zum Essen und Trinken
          </TextLink>
          .
        </Paragraph>
        <Paragraph>
          Ihre Jacke und Ihre Tasche müssen Sie an der Garderobe lassen. <br />
          Dort gibt es Schließ∙fächer. <br />
          In den Schließ∙fächern können Sie Ihre Sachen ein∙schließen.
        </Paragraph>
        <Divider />

        <Paragraph>
          Gerade gibt es <MarkerHighlight>besondere Regeln</MarkerHighlight>:
        </Paragraph>

        <Paragraph>Am Eingang müssen Sie diese Daten angeben:</Paragraph>

        <BulletList size={[3, 3, 4, 5]}>
          <Bullet>Ihren Namen</Bullet>
          <Bullet>Ihre Adresse</Bullet>
          <Bullet>Ihre Telefonnummer</Bullet>
        </BulletList>

        <Paragraph>
          Im Turm müssen Sie einen Mund-Nasen-Schutz tragen. <br />
          Und Sie müssen zu anderen Menschen 1,5 Meter Abstand halten.
        </Paragraph>

        <Paragraph>
          Diese Regeln gibt es wegen dem Corona-Virus. <br />
          Vielleicht ist das Corona-Virus bald nicht mehr so gefährlich. <br />
          Dann gibt es diese Regeln nicht mehr.
        </Paragraph>
      </Stack>
    </Layout>
  )
}

export default LeichteSpracheRegeln
